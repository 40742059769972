.privacy-list a {
    color: inherit;
    text-decoration: underline;
}
ul {
    list-style-type: none;
}
.main {
    background-color: #f7f7f7;
    padding-top: 56px;
    @media (min-width: 992px) {
        max-width: calc(100% - 265px);
        margin-left: auto;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        max-width: calc(100% - 75px);
        margin-left: auto;
    }
}

.menu-collapse {
    &#sidebar {
        left: -5px !important;
        border: none !important;
        max-width: 100%;
        @media (max-width: 992px) {
            width: 260px !important;
            left: 260px !important;
            z-index: 3;
        }
    }
    &#toggle-btn {
        transform: rotate(90deg);
    }
    &#main,
    &.main-footer {
        max-width: 100%;
    }
    &#navbar-wrapper {
        width: 100%;
    }
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.card-background {
    height: 360px;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.72) 78.72%);
    .btn {
        align-self: baseline;
        margin-bottom: 0.5rem;
    }
}

.top-list {
    width: 100%;
    display: grid;
    gap: 2rem;
    li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f7f7f7;
        padding: 15px 1rem;
        border-radius: 5px;
        h5 {
            margin-bottom: 0 !important;
        }
        figure {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            margin: 0 !important;
            figcaption {
                display: block;
                max-width: 146px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
            img {
                margin: 0 1rem;
            }
        }
    }
}

.recenty-played-wrapper {
    display: grid;
    gap: 0.5rem;
    width: 100%;
    li {
        padding: 1rem 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #f7f7f7;
        border-radius: 5px;
    }
}

.figure-wrapper {
    align-items: center;
    justify-content: space-between;
    .top-list li figure figcaption {
        display: block;
        max-width: 146px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
    p {
        min-width: 20px;
    }
}

.figure {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    img {
        width: 50px;
        height: 50px;
        margin-right: 1rem;
    }
    figcaption {
        display: grid;
    }
}

.like-wrapper {
    width: 100%;
    max-width: 131px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    @media (max-width: 576px) {
        justify-content: center;
        width: auto !important;
        align-self: center;
    }
    p {
        @media (max-width: 576px) {
            display: none;
        }
        color: black;
    }
}

.email-wrapper {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .text-sm {
        font-size: 15px;
    }
    .text-xs {
        font-size: 13px;
    }
    h1 {
        width: 100% !important;
    }
    .form {
        background: #ffffff;
        max-width: 628px;
        margin: auto;
        margin-top: 2rem;
        form {
            display: flex;
            justify-content: space-between;
            margin: 0 1rem;
            flex-wrap: wrap;
            padding: 9.28px 0;
        }
        justify-content: space-between;
        input[type="email"]:focus,
        input[type="email"] {
            outline: none;
            border: none;
        }
        input[type="submit"] {
            background: linear-gradient(99.52deg, #a13893 5.69%, #629bee 97.44%);
            outline: none;
            border: none;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: -0.5px;
            text-align: center;
            color: white;
            border-radius: 10px;
            padding: 0.875rem 1.81rem !important;
        }
        border-radius: 10px;
    }
}

.top-header {
    p {
        font-size: 13px;
        letter-spacing: -0.5px;
        line-height: 18px;
        color: #000000;
    }
    .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    .btn:first-of-type {
        width: 100%;
        max-width: 264px;
        min-height: 41px;
        display: block;
        display: flex;
    }
}

.page-subtitle {
    font-size: 13px;
    letter-spacing: -0.5px;
    line-height: 18px;
    color: #000000;
    max-width: 454px;
    margin-top: 0.5rem;
}

.top-artist-list {
    width: 100%;
    margin-bottom: 0 !important;
    display: grid;
    gap: 0.5rem;
    li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: #f7f7f7;
        border-radius: 5px;
        h5 {
            margin-bottom: 0;
        }
        .play-wrapper {
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 2rem;
            align-items: center;
            justify-content: space-between;
        }
        figure {
            display: flex;
            align-items: center;
            img {
                margin-right: 1rem;
            }
            figcaption {
                display: grid;
            }
        }
    }
}

.login-form {
    align-self: start;
    margin-top: 35px;
    width: 100%;
    form {
        display: grid;
    }
    .custom-select {
        width: 100% !important;
    }
}

.login-info {
    font-size: 9px;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.player-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
}

.echoes-player {
    background-color: #282828;
    border-top: 1px solid #282828;
    font-size: 14px;
    width: 100%;
    line-height: 17px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.5px;
    color: #ffffff;
    padding: 1rem;
    .album-info {
        @media (max-width: 576px) {
            display: none !important;
        }
        display: flex;
        align-items: center;
        flex: 1;
        .figure-artist-name {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.5px;
            text-align: left;
            opacity: 0.7;
            margin-top: 0.5rem;
        }
        .figure-track-name {
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: -0.5px;
            text-align: left;
            display: inline-block;
            display: block;
            max-width: 146px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        svg {
            margin-right: 1.5rem;
        }
    }
    .player {
        width: 100%;
        flex: 3;
        max-width: calc(100% - (265px - 1rem));
        @media (max-width: 576px) {
            max-width: none;
        }
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        .time-rest,
        .time-past {
            opacity: 0.7;
            font-size-adjust: 0.58;
            font-variant-numeric: tabular-nums;
            font-feature-settings: "tnum";
        }
        &-buttons {
            display: flex;
            align-items: center;
            min-width: 128px;
            justify-content: space-around;
        }
        input[type="range"] {
            width: calc(100% - 100px);
        }
        .progress-wrapper {
            margin-top: 1rem;
            width: 100%;
            max-width: 620px;
            min-width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

progress::-webkit-progress-value {
    background-image: -webkit-linear-gradient(
            -45deg,
            transparent 33%,
            rgba(0, 0, 0, 0.1) 33%,
            rgba(0, 0, 0, 0.1) 66%,
            transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left, #09c, #f44);
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius: 5px;
}

progress::-webkit-progress-value {
    background-image: -webkit-linear-gradient(
            -45deg,
            transparent 33%,
            rgba(0, 0, 0, 0.1) 33%,
            rgba(0, 0, 0, 0.1) 66%,
            transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left, #09c, #f44);
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius: 5px;
}

progress::-moz-progress-bar {
    border-radius: 5px;
}

progress::-webkit-progress-bar {
    border-radius: 5px;
}

.PlayerRSWP {
    background-color: #282828 !important;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column-reverse;
    [role="presentation"] {
        max-width: 500px;
        margin: auto;
    }
}

.body {
    height: 100vh;
    display: grid;
    place-content: center;
}

.range-picker {
    display: flex;
    position: relative;
    width: 158px;
    height: 32px;
    padding: 0 13.5px;
    align-items: center;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    i {
        z-index: 1;
    }
    select {
        // width: calc(100% - (13.5px + 18px));
        width: 100%;
        text-indent: 35px;
        right: 0;
        top: 1px;
        height: 100%;
        border: none;
        outline: none;
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        option {
            padding: 0;
        }
    }
}

.create-playlist-section {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    bottom: 100px;
    width: 88%;
    margin-bottom: 1rem;
    color: white;
}

.rotate {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    -webkit-animation-fill-mode: forwards;
    /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
}

.rotate-stop {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    -webkit-animation-fill-mode: forwards;
    /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
    animation-play-state: paused;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.volume-wrapper {
    flex: 1;
    align-self: flex-end;
    display: flex;
    min-width: 225px;
    @media (max-width: 576px) {
        margin-top: 0.5rem;
    }
}

.modal {
    position: fixed;
    display: grid;
    z-index: 4;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 83px);
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    place-items: center;
    animation: ModalShow 0.1s ease-out;
    height: 100%;
}

@keyframes ModalShow {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.modal-overlay {
    width: 100%;
    height: 100%;
    background: white;
}

.modal-content {
    margin: auto;
    width: 100%;
    max-width: 950px;
    background: #ffffff;
    z-index: 4;
    .card-body {
        flex-direction: column;
        display: flex;
        .title {
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 44px;
            letter-spacing: -2.5px;
        }
        .sub-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.5px;
            color: #7a7a7a;
            margin-top: 0.5rem;
        }
    }
}

.modal-wrapper {
    width: 100%;
}

.main-footer {
    margin-bottom: 6.4rem;
    @media (max-width: 456px) {
        margin-bottom: 8.4rem;
    }
    a {
        text-decoration: none;
        color: inherit;
        align-items: center;
    }
    .footer-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: -0.5px;
        margin-right: 1rem;
    }
}

.figure-subtitle {
    font-size: 13px;
    color: #7a7a7a;
}
.playlist-text-img {
    object-fit: contain;
}

.announcekit-widget-badge {
    position: absolute !important;
    bottom: 15px;
}

.announcekit-frame-wrapper {
    top: 41px !important;
    left: -279px !important;
    width: 344px !important;
}

.tooltip-playlist {
    position: relative;
    display: block;
    position: absolute;
    background: white;
    width: 228px;
    left: 0px;
    bottom: 50px;
    border-radius: 6px;
    display: block;
    animation: ModalShow 0.1s ease-out;
    color: #000000;
    &:focus {
        outline: none;
    }
    h5 {
        font-weight: 500;
        font-size: 14px;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #ebebeb;
    }
    h6 {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.5px;
        text-align: left;
        color: #7a7a7a;
    }
    ul {
        display: grid;
        gap: 18px;
        margin-bottom: 0 !important;
        margin-block-end: 0 !important;
        margin-block-start: 0 !important;
        list-style-type: none;
        padding-top: 1rem;
        svg {
            margin-right: 12px;
        }
        li {
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.5px;
            text-align: left;
        }
    }
}
.menu-disabled {
    cursor: default;
    span {
        color: #b2b2b2;
    }
}

input[type="range"] {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 6px;
    width: 200px;
    cursor: pointer;
    border-radius: 0; /* iOS */
    background-color: #535353;
    border-radius: 5px;
}

::-webkit-slider-runnable-track {
    background: #535353;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    // height: 6px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw #b2b2b2;
    // border: 2px solid #999; /* 1 */
}

::-moz-range-track {
    height: 6px;
    background: #ddd;
}

::-moz-range-thumb {
    background: #fff;
    height: 6px;
    width: 20px;
    border: 3px solid #999;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw #b2b2b2;
    box-sizing: border-box;
}

::-ms-fill-lower {
    background: #b2b2b2;
}

::-ms-thumb {
    background: #fff;
    border: 2px solid #999;
    height: 6px;
    width: 20px;
    box-sizing: border-box;
}

::-ms-ticks-after {
    display: none;
}

::-ms-ticks-before {
    display: none;
}

::-ms-track {
    background: #ddd;
    color: transparent;
    height: 6px;
    border: none;
}

::-ms-tooltip {
    display: none;
}

.privacy-list {
    display: grid;
    font-size: 18px;
    gap: 2rem;
}

.privacy-version {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.5px;
    text-align: left;
}

.privacy-hr {
    background: #000000 %80 !important;
}

.privacy-bottom {
    margin-top: 5rem;
}

.bg-gradient {
    background: linear-gradient(99.52deg, #a13893 -3.31%, #629bee 46.44%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

#my-node {
    border-radius: 0;
    opacity: 0;
    height: 0;
    z-index: -1;
}

.top-text {
    h1 {
        font-size: 73px;
        font-weight: 700;
        letter-spacing: -2.33918px;
        font-weight: 800;
        line-height: 1.02;
        @extend .bg-gradient;
        &-small {
            font-size: 37px;
            font-weight: 700;
        }
    }
}

.top-logo {
    max-width: 320px !important;
}

.top-logo-text {
    font-weight: 400;
    line-height: 50px;
    font-size: 9.36px;
}
.top-logo-link {
    background: linear-gradient(99.52deg, #a13893 5.69%, #629bee 97.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.playlist-modal {
    padding: 0 2rem !important;
    display: grid;
    grid-template-columns: 300px 1fr;
}

.figure-playlist {
    .track {
        font-size: 12px !important;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
    &:nth-child(1) {
        background: rgba(39, 193, 149, 1) !important;
        padding: 15px;
        border-radius: 10px;
        color: black !important;
        .figure-subtitle {
            color: black;
        }
    }
    .figure-subtitle {
        font-size: 9px;
        font-weight: 500;
        color: white;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}

.top-playlist-wrapper {
    padding: 1.5rem;
    padding-left: 0;
    display: grid;
    grid-template-columns: 250px 250px;
    align-content: baseline;
    row-gap: 14px;
}
.copy-wrapper {
    background-color: rgba(247, 248, 249, 1);
    height: 70px;
    display: flex;
    align-items: center;
    max-width: 382px;
    padding: 1rem;
    border-radius: 10px;
    input {
        height: 46px;
        border: none;
        border-right: 1px solid #828d9c;
        outline: none;
        width: 100%;
        max-width: 335px;
        color: #828d9c;
        background: transparent;
    }
}

.social-share-wrapper {
    a:not(:last-child) {
        margin-right: 0.5rem;
    }
}

#playlist-cover {
    height: 390px;
    width: 100%;
    background-size: cover;
}

.close-btn {
    cursor: pointer;
    height: 34px;
    @media (min-width: 992px) {
        right: -58px;
        top: 0;
        position: absolute;
    }
}

.listen-btn {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: -0.5px;
    line-height: 1;
}

.listen-here-text {
    display: none;
    @media (min-width: 992px) {
        display: block;
        margin-left: 0.5rem;
    }
}
